import React from "react";
import fim from "../img/sponsors/fim-europe.png";
import partille from "../img/sponsors/partille-trial.png";
import runskriften from "../img/sponsors/runskriften.png";
import svemo from "../img/sponsors/svemo.png";
import peach from "../img/sponsors/peach.png";
import alekuriren from "../img/sponsors/alekuriren.png";
import brogren from "../img/sponsors/brogren.png";
import magnusson from "../img/sponsors/magnusson.png";
import copycentrum from "../img/sponsors/copycentrum.png";
import markbolaget from "../img/sponsors/markbolaget.png";
import bohus from "../img/sponsors/bohus.png";
import s3 from "../img/sponsors/s3.png";

const Sponsors = () => {
    return (
        <>
            <div className="section-component" id="sponsors">
                <div className="sponsors-component">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2>Our sponsors</h2>
                                <div className="m-4">
                                    <a>
                                        <img className="my-0" src={runskriften} alt="aletrial"></img>
                                    </a>
                                    <a href="https://www.fim-moto.com/en/">
                                        <img className="my-0" src={fim} alt="fim"></img>
                                    </a>
                                    <a>
                                        <img className="my-0" src={partille} alt="partille" href=""></img>
                                    </a>
                                    <a href="https://gopeach.se/">
                                        <img src={peach} alt="peach"></img>
                                    </a>
                                    <a href="https://www.svemo.se/">
                                        <img src={svemo} alt="svemo"></img>
                                    </a>
                                    <a href="https://www.alekuriren.se/">
                                        <img src={alekuriren} alt="alekuriren"></img>
                                    </a>
                                    <a href="https://www.brogrenindustries.com/">
                                        <img src={brogren} alt="brogren"></img>
                                    </a>
                                    <a href="https://www.bohusdack.se/">
                                        <img src={bohus} alt="bohus"></img>
                                    </a>
                                    <a href="https://www.copycentrum.se/">
                                        <img src={copycentrum} alt="copycentrum"></img>
                                    </a>
                                    <a href="https://www.markbolaget.se/">
                                        <img src={markbolaget} alt="markbolaget"></img>
                                    </a>
                                    <a href="https://www.magnusson-freij.se/">
                                        <img src={magnusson} alt="magnusson"></img>
                                    </a>
                                    <a href="https://www.s3parts.com/en/">
                                        <img src={s3} alt="s3"></img>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sponsors;
