import React from 'react';
import { Link } from 'react-router-dom';
import EntryList from '../assets/entryList.pdf';

const Participants = () => {
	return (
		<>
			<div
				className="section-component"
				id="participants">
				<div className="participants-component">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-8 mb-5">
								<div className="justify-content-center">
									<h2 className="text-center">PARTICIPANTS</h2>
								</div>
								<div className="text-center mt-5">
									<Link
										className="primary-btn mx-auto"
										to={EntryList}
										target="__blank">
										<span className="mx-2">View participants</span>
										<span className="mx-2">→</span>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Participants;
