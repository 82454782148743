import React from "react";
import ParticipantsInfo from "../components/ParticipantsInfo";
import Contact from "../components/Contact";

const Participants = () => {
    return (
        <>
            <div id="participantsPage"></div>
            <ParticipantsInfo />
            <Contact />
        </>
    );
};

export default Participants;
