import React from "react";
import Hero from "../components/Hero";
import FindUs from "../components/FindUs";
import About from "../components/About";
import Contact from "../components/Contact";
import WhatIsTrial from "../components/WhatIsTrial";
import Participants from "../components/Participants";
import Sponsors from "../components/Sponsors";

const Home = () => {
    return (
        <>
            <Hero />
            <FindUs />
            <WhatIsTrial />
            <Participants />
            <About />
            <Sponsors />
            <Contact />
        </>
    );
};

export default Home;
