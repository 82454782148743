import React from "react";
import Mail from "../img/mail.svg";
import Phone from "../img/phone.svg";

const Contact = () => {
    return (
        <>
            <div className="section-component" id="contact">
                <div className="contact-component">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="text-center">
                                    <h2>Contact Us</h2>
                                    <div className="d-flex justify-content-center">
                                        <img className="me-2" src={Mail} alt=""></img>
                                        <a href="mailto:ec.trial.ale@gmail.com">
                                            <p className="m-0">ec.trial.ale@gmail.com</p>
                                        </a>
                                    </div>
                                    <div className="d-flex justify-content-center mt-4">
                                        <img className="me-2" src={Phone} alt=""></img>
                                        <a href="tel:+46703094791">
                                            <p className="m-0">Peter Alfredsson</p>
                                            <p className="m-0">070 - 309 47 91</p>
                                        </a>
                                    </div>
                                    <div className="d-flex justify-content-center mt-4">
                                        <img className="me-2" src={Phone} alt=""></img>
                                        <a href="tel:+46709994563">
                                            <p className="m-0">Börje Andermård</p>
                                            <p className="m-0">070 - 999 45 63</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;
