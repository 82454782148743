import React from "react";
import heroLogo from "../img/trial-logo.png";

const Hero = () => {
    return (
        <>
            <div className="section-component">
                <div className="hero-component">
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div className="text-white text-center p-0">
                            <img src={heroLogo} alt="" />
                            <div className="col-6 mx-auto mt-5"></div>
                            <div className="box">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Hero;
