import React from 'react';

function MapEmbed() {
	let coordinates = '57.82635515, 12.02900997';

	return (
		<iframe
			src="https://maps.google.com/maps?q=57.82635515,12.02900997&hl=es;z=14&amp;output=embed"
			width="100%"
			height="450"
			style={{ border: 0 }}
			allowFullScreen=""
			loading="lazy"></iframe>
	);
}

const FindUs = () => {
	return (
		<>
			<div className="container">
				<h1 className="text-center">
					Trial European Championship <br></br>2023 - 19-20 Aug - Surte, Sweden
				</h1>
			</div>
			<div
				className="section-component"
				id="findus">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6">
							<div className="findus-text">
								<h2> FIND US</h2>
								<h4 className="text-white findus-text mb-3">
									EM Trial 19-20 August
								</h4>
								<p className="text-white findus-text mb-3">
									The competition starts at 8 AM on both Saturday and Sunday at
									Kleverbergsbanan in Surte, within Ale municipality in
									Göteborg.
									<br />
									<br />
									There will be food services by the visitors parking. (More
									information to come)
								</p>
								<p className="text-white findus-text">
									Entrance fee: send any amount to Ale Trial Club and Partille
									Trial Club's youth activities via Swish.
								</p>
							</div>
						</div>
						<div className="col-12 col-lg-6">
							<div className="map-box">
								<div className="map">
									<MapEmbed />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default FindUs;
