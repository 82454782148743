import React from "react";
import Logo from "../img/trial-logo.png";

const Footer = () => {
    return (
        <>
            <div className="footer-component">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="divider mx-auto"></div>
                            <div className="text-center">
                                <div className="d-flex justify-content-center">
                                    <img src={Logo} alt=""></img>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 p-0">
                            <div className="text-center">
                                <div className="footer-bottom d-flex justify-content-center">
                                    <p className="m-0">© 2023 TRIAL Euro</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;
