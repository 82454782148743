import React from 'react';
import TimeTable from './TimeTable.jsx';
import image from '../img/trial.png';

const ParticipantsInfo = () => {
	return (
		<>
			<div className="section-component pb-0">
				<div className="whatistrial-component">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-12 col-md-8 order-last">
								<img
									className="img-fluid"
									src={image}
									alt=""
								/>
							</div>
							<div className="col-12 col-md-8 mb-5 order-lg-first">
								<div className="mx-auto d-flex flex-column justify-content-center h-100">
									<h2 className="mb-4"> For participants</h2>
									<h4 className="text-white">EM trial 19-20 august</h4>
									<p className="text-white">
										Sign up starts on the 18th of august, while the depot opens
										on the 17th of august at 3PM.
									</p>
									<p className="text-white">
										There will be an information center at our Race Office, as
										well as access to electricity within the depot. Food will be
										served at the competition area, and you will have access to
										bathrooms and showers.
									</p>

									<p>
										There will be a competition for 2 days in Sweden. Saturday
										19/8 a race for all classes and a race on Sunday 20/8 for
										all classes
									</p>
									<h4 className="text-white">Organisation</h4>
									<ul className="text-white mx-0">
										<li>
											President of the Jury: FIM Europé Nick Vren (England)
										</li>
										<li>Clerk of the Course: Magnus Liljeblad</li>
										<li>Assistant Clerk of the Course: Peter Alfredsson</li>
										<li>Chief Technical Steward: George Hippel</li>
										<li>Environment Official: Bernt Johansson</li>
									</ul>

									<h4 className="text-white">
										There will be 8 classes in Sweden
									</h4>
									<ul className="text-white mx-0">
										<li>Trial Championship</li>
										<li>Women Championship</li>
										<li>Junior Championship</li>
										<li>Youth Championship</li>
										<li>Over 40 Championship</li>
										<li>International Class</li>
										<li>Women International</li>
										<li>Youth International</li>
									</ul>
									<h4 className="text-white">Hotel information</h4>
									<ul className="text-white px-0">
										<p>
											<strong>Hotel Surte AB</strong> <br></br>Göteborgsvägen
											24-26<br></br> 445 57 Surte, Sweden <br></br> +46 31 98 05
											80<br></br> www.hotelsurte.se info@hotelsurte.se
										</p>
										<p>
											<strong>Hotell Fars Hatt</strong> <br></br>Gamla Torget 2
											<br></br> 442 31 Kungälv, Sweden <br></br> +46 303 109 70
											<br></br> www.farshatt.se info@farshatt.se
										</p>
									</ul>
									<h4 className="text-white">Access</h4>
									<ul className="text-white px-0">
										<p>
											Paddock GPS coordinates: 57.8263317, 12.0290932<br></br>{' '}
											Nearest airport: Landvetter Airport Access <br></br>{' '}
											Motorway and Exit: Motorway number: E45, Exit number: 81
											<br></br> Nearest Town: Kungälv, distance: 8km Nearest
											Hospital: Kungälvs Sjukhus
										</p>
									</ul>
									<h4 className="text-white">Payment</h4>
									<ul className="text-white px-0">
										<p>
											Payment is possible with Visa/Mastercard or cash in
											EURO/SEK
										</p>
									</ul>
									<h4 className="text-white">Timetable</h4>
									<ul className="text-white px-0">
										<TimeTable />
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ParticipantsInfo;
