import React from 'react';

const TimeTable = () => {
	return (
		<>
			<style>
				{`
				table {
					border-collapse: collapse;
					width: 100%;
					border: 2px solid white;
				}
				th,
				td {
					border: 1px solid white;
					padding: 8px;
				}
				`}
			</style>
			<div className="timetable">
				{/* THURSDAY */}
				<table className="mb-4">
					<tr>
						<th>Day</th>
						<th>Time</th>
						<th>Two days event all classes each day</th>
					</tr>
					<tr>
						<td rowspan="3">Thursday 17/8</td>
						<td>15:00</td>
						<td>
							Paddock will be open from 15:00 (Please wait for instructions from
							Paddock manager before parking)
						</td>
					</tr>
					<tr>
						<td>18:00</td>
						<td>Voting jury: Meeting with organisers 18:00</td>
					</tr>
					<tr>
						<td>15:00-18:00</td>
						<td>Food will be availble between in the main paddock</td>
					</tr>
				</table>
				{/* FRIDAY */}
				<table className="mb-4">
					<tr>
						<td rowspan="8">Friday 18/8</td>
						<td>09:00</td>
						<td>Jury inspection of the sections</td>
					</tr>
					<tr>
						<td>09:00-13:00</td>
						<td>
							Registration and Technical control for driver and minder in all
							classes at Raceoffice
						</td>
					</tr>
					<tr>
						<td>11:00-15:00</td>
						<td>Practise area opened</td>
					</tr>
					<tr>
						<td>14:00-17:00</td>
						<td>Riders visit of the sections</td>
					</tr>
					<tr>
						<td>18:00</td>
						<td>1st Jury Meeting</td>
					</tr>
					<tr>
						<td>10:00-18:00</td>
						<td>
							Food will be availble in our restaurant (Visa/Mastercard or cash
							EURO/SEK)
						</td>
					</tr>
				</table>
				{/* SATURDAY */}
				<table className="mb-4">
					<tr>
						<td rowspan="10">Saturday 19/8</td>
						<td>07:00</td>
						<td>Meeting for sections crew at Raceoffice</td>
					</tr>
					<tr>
						<td>11:00</td>
						<td>Start of the last rider</td>
					</tr>
					<tr>
						<td>16:00</td>
						<td>Arrival of the last rider</td>
					</tr>
					<tr>
						<td>16:30</td>
						<td>Price-giving ceremony</td>
					</tr>
					<tr>
						<td>17:00</td>
						<td>2nd Jury Meeting</td>
					</tr>
					<tr>
						<td>07:30-18:00</td>
						<td>
							Food will be availble in our restaurant (Visa/Mastercard or cash
							EURO/SEK)
						</td>
					</tr>
				</table>
				{/* SUNDAY */}
				<table className="mb-4">
					<tr>
						<td rowspan="10">Sunday 20/8</td>
						<td>07:00</td>
						<td>Meeting for sections crew at Raceoffice</td>
					</tr>
					<tr>
						<td>11:00</td>
						<td>Start of the last rider</td>
					</tr>
					<tr>
						<td>16:00</td>
						<td>Arrival of the last rider</td>
					</tr>
					<tr>
						<td>16:30</td>
						<td>Price-giving ceremony</td>
					</tr>
					<tr>
						<td>17:00</td>
						<td>2nd Jury Meeting</td>
					</tr>
					<tr>
						<td>07:30-18:00</td>
						<td>
							Food will be availble in our restaurant (Visa/Mastercard or cash
							EURO/SEK)
						</td>
					</tr>
				</table>
			</div>
		</>
	);
};

export default TimeTable;
