import React from "react";
import image from "../img/trial.png";

const About = () => {
    return (
        <>
            <div className="section-about" id="about">
                <div className="about-component">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6 order-last order-lg-first">
                                <img className="img-fluid" src={image} alt=""></img>
                            </div>
                            <div className="col-12 col-lg-6 mb-5">
                                <div className="mx-auto d-flex flex-column justify-content-center h-100">
                                    <h2 className="m-0"> About us</h2>
                                    <p className="text-white">
                                        Ale Trial Club is based in the municipality of Ale, est. 30 kilometers outside
                                        of Gothenburg in Västra Götaland county. The club was founded in 2001 by the
                                        merger of a few minor clubs and consists of just over 100 members, representing
                                        all ages. This makes us the largest trial club in Sweden, which has led to us
                                        having a myriad of talented drivers throughout all classes. Our home track is
                                        Klevebergsbanan just by Surtesjön, where we arrange training sessions, local
                                        competitions, Swedish Championships, as well as international competitions at
                                        Klevebergsbanan.
                                    </p>
                                    {/* <div className="mt-4">
                                        <a className="secondary-btn participants-btn text-center">For participants</a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;
