import React from "react";
import { Route, Routes } from 'react-router-dom'
import Home from "./pages/Home";
import Participants from "./pages/Participants";
import Header from "./components/Header";
import Footer from "./components/Footer";

const App = () => {
    return (
        <>
        <Header />
        <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/participants" element={<Participants />}></Route>
        </Routes>
        <Footer />
        </>
    );
};

export default App;
