import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Logo from "../img/logo.png";
import Offcanvas from "react-bootstrap/Offcanvas";
import Facebook from "../img/facebook.svg";
import Instagram from "../img/instagram.svg";
import { HashLink } from "react-router-hash-link";

const Header = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div id="home"></div>
            <div className="header sticky-top">
                {/* Desktop */}
                <Navbar expand="lg">
                    <div className="container-fluid container-md">
                        <HashLink className="navbar-brand" to="/#home">
                            <img className="logo" src={Logo} />
                        </HashLink>

                        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleShow} />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto">
                                <HashLink className="nav-link" to="/#findus">
                                    Find us
                                </HashLink>

                                <HashLink className="nav-link" to="/#whatistrial">
                                    What is Trial?
                                </HashLink>

                                <HashLink className="nav-link" to="/#participants">
                                    Participants
                                </HashLink>

                                <HashLink className="nav-link" to="/#about">
                                    About us
                                </HashLink>

                                <HashLink className="nav-link" to="/#sponsors">
                                    Sponsors
                                </HashLink>

                                <HashLink className="nav-link" to="/#contact">
                                    Contact
                                </HashLink>

                                <HashLink className="primary-btn mx-4" to="/participants#participantsPage">
                                    For participants
                                </HashLink>

                                <Link className="my-auto" to="https://www.facebook.com/aletrialklubb/" target="_blank">
                                    <img className="socials mx-2" src={Facebook} />
                                </Link>
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                </Navbar>

                {/* Mobile */}
                <Offcanvas show={show} onHide={handleClose} placement="end">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="flex-column justify-content-center align-content-center text-center">
                            <HashLink className="nav-link" to="/#findus" onClick={handleClose}>
                                Find us
                            </HashLink>

                            <HashLink className="nav-link" to="/#whatistrial" onClick={handleClose}>
                                What is Trial?
                            </HashLink>

                            <HashLink className="nav-link" to="/#participants" onClick={handleClose}>
                                Participants
                            </HashLink>

                            <HashLink className="nav-link" to="/#about" onClick={handleClose}>
                                About us
                            </HashLink>

                            <HashLink className="nav-link" to="/#sponsors" onClick={handleClose}>
                                Sponsors
                            </HashLink>

                            <HashLink className="nav-link" to="/#contact" onClick={handleClose}>
                                Contact
                            </HashLink>

                            <HashLink
                                className="primary-btn my-4 w-100"
                                to="/participants#participantsPage"
                                onClick={handleShow}
                            >
                                For participants
                            </HashLink>

                            <div className="d-flex m-auto pt-4">
                                <img
                                    className="socials mx-2"
                                    to="https://www.facebook.com/aletrialklubb/"
                                    src={Facebook}
                                />
                            </div>
                        </Nav>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </>
    );
};

export default Header;
