import React from "react";
import imageOne from "../img/info/img1.jpg";
import imageTwo from "../img/info/img2.jpg";
import imageThree from "../img/info/img3.jpg";
import imageFour from "../img/info/img4.jpg";
import imageFive from "../img/info/img5.jpg";
import imageSix from "../img/info/img6.jpg";

const WhatIsTrial = () => {
    return (
        <>
            <div className="section-component" id="whatistrial">
                <div className="whatistrial-component">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 mb-5">
                                <div className="mx-auto whatistrial-text">
                                    <h2> What is Trial</h2>
                                    <p className="text-white">
                                        Motorcycle trials or simply Trial is a motorised sport with the goal of
                                        traversing a obstacle course with a motorbike, while never letting your feet
                                        touch the ground or loose your balance. The course consists of all manner of
                                        obstacles including boulders, logs, stairs and cliffs. Trial is a technical
                                        sport that demands precision, skill and a extraordinary mental capacity by the
                                        driver. Competitions within the sport is divided into different classes, based
                                        on age and levels of experience. Trial is popular around the world and has it’s
                                        own European Championships and World Championships.
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4  d-flex justify-content-center">
                                <img className="img-fluid" src={imageOne} alt=""></img>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center">
                                <img className="img-fluid" src={imageTwo} alt=""></img>
                            </div>
                            <div className="col-12  col-md-6 col-lg-4 d-flex justify-content-center">
                                <img className="img-fluid" src={imageThree} alt=""></img>
                            </div>
                            <div className="col-12  col-md-6 col-lg-4 d-flex justify-content-center">
                                <img className="img-fluid" src={imageFour} alt=""></img>
                            </div>
                            <div className="col-12  col-md-6 col-lg-4 d-flex justify-content-center">
                                <img className="img-fluid" src={imageFive} alt=""></img>
                            </div>
                            <div className="col-12  col-md-6 col-lg-4 d-flex justify-content-center">
                                <img className="img-fluid" src={imageSix} alt=""></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WhatIsTrial;
